import React from 'react';
import { Text, Flex, GlobalStyle } from 'pretty-decent-ui';

import Layout from "../components/layout"


import theme from '../theme';

const Components = {
  'div': "div",
  'text': Text,
  'flex': Flex,
};


const renderContent = ({ fullContent, children = ['root'] }) => {
  console.log({ children });

  return children.map((childId) => {
    const { uid, props, type, children } = fullContent[childId]
    const Component = Components[type];
    if (!Component) {
      console.error("Component type " + type + "is not defined");
      return null;
    }
    if (typeof children === "string") {
      const Component = Components[type];
      return <Component key={uid} {...props}>{children}</Component>
    }

    return <Component key={uid} {...props}>{renderContent({ fullContent, children })}</Component>
  })
}

const Main = (props) => {
  console.log({ props });
  const { pageContext } = props;
  return (

    <Layout>
      <div className="hellyeah">
        {renderContent({
          fullContent: pageContext.content,
        })}
      </div>
    </Layout>
  )
}


export default Main
